<template>
  <div class="operation-log" ref="operationLog">
    <div class="form-area logForm" ref="form">
      <el-form
        :model="form"
        ref="formInline"
        :rules="formRules"
        class="demo-form-inline"
        label-position="left"
        label-width="82px"
      >
        <!-- 选择时间： -->
        <el-form-item label="选择时间：" prop="time" style="margin-right: 20px">
          <el-date-picker
            cellClassName="timeRangePicker"
            v-model="form.time"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="timestamp"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="操作人：">
          <el-input v-model="form.operationName" placeholder="请输入操作人">
          </el-input>
        </el-form-item>
        <el-form-item label="操作菜单：">
          <el-select
            v-model="form.catalogue"
            clearable
            placeholder="请选择操作菜单"
          >
            <el-option
              v-for="item in menuList"
              :label="item.desc"
              :value="item.code"
              :key="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作方式：">
          <el-select
            v-model="form.operationType"
            clearable
            placeholder="请选择操作方式"
          >
            <el-option label="新增" value="POST"></el-option>
            <el-option label="修改" value="PUT"></el-option>
            <el-option label="删除" value="DELETE"></el-option>
            <!-- <el-option label="导出" value="导出"></el-option>
            <el-option label="禁用" value="禁用"></el-option>
            <el-option label="启用" value="启用"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="table-expand">
            <p v-for="(item, key) in props.row.content.split(',')" :key="key">
              <span>{{ item.replace("{", "").replace("}", "") }}</span>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column type="index" label="序号"></el-table-column>

      <el-table-column
        prop="operationName"
        label="操作人"
        width="160"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="operationId" label="账号"></el-table-column>
      <el-table-column
        prop="createTime"
        label="操作时间"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="catalogue"
        label="操作菜单"
        width="200"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="operationTypeName"
        label="操作方式"
      ></el-table-column>
      <el-table-column
        prop="content"
        label="操作内容"
        width="600"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getRangeDay,
  formatDate,
  checkTimeLimitDay
} from '@/common/utils/index'
import { queryUserOperationLog, getAllCatalogue } from '@/api/lib/api.js'
export default {
  name: 'operationLog',
  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (value) {
        if (new Date() <= value[0] || new Date() <= value[1]) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateDate1 = (rule, value, callback) => {
      if (value) {
        if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
          callback(new Error(`只能查询${this.timeLimit}天内的数据`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      timeLimit: 60,
      form: {
        time: null,
        operationName: null,
        catalogue: null,
        operationType: null,
        dataType: null,
        currentPage: 1,
        pageSize: 10
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = getRangeDay()[1]
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = getRangeDay()[1]
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formRules: {
        time: [
          { validator: validateDateForward, trigger: 'change' },
          { validator: validateDate1, trigger: 'change' }
        ]
      },
      menuList: [],
      equipItem: null,
      title: '',
      dialogVisible: false,
      searchLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.operationLog.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 16
    },
    // 点击查询
    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    //获取数据
    getListByField () {
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          let data = { ...this.form }
          if (data.time) {
            data.operationDateStart = formatDate(this.form.time[0])
            data.operationDateEnd =
              formatDate(this.form.time[1]).split(' ')[0] + ' 23:59:59'
          }
          delete data.time
          queryUserOperationLog(data).then((res) => {
            if (res.code === 1000) {
              this.tableData = res.data.list
              this.total = res.data.total
            } else {
              this.tableData = []
              this.total = 0
              this.$message.error(res.msg)
            }
            this.searchLoading = false
          })
        } else {
          this.searchLoading = false
        }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },

    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getListByField()
    },
    //获取字典值
    getCatalogues () {
      getAllCatalogue().then((res) => {
        if (res.code === 1000) {
          this.menuList = res.data
        }
      })
    }
  },
  created () {
    this.getCatalogues()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.operation-log {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  @include themify() {
    .table-expand {
      font-size: 0;
      display: inline-grid;
      width: calc(100% - 3vh);
      grid-template-columns: repeat(4, 1fr);
      p {
        font-size: $base-font-size-default;
        margin-bottom: 10px;
        height: $base-input-height;
        line-height: $base-input-height;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.theme-project-resourse {
  .operation-log {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
